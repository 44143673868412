import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Header = ({ toggleMenu }) => (
  <header
    role="menu"
    tabIndex={0}
    onClick={toggleMenu}
    onKeyDown={e => {
      if (e.key === "Enter") toggleMenu()
    }}
  >
    <div className="wrapper">
      <Link to="/" className="branding">
        <StaticImage
          src="../images/logomark-green.png"
          alt="The Joy of Tai Chi"
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
        />
      </Link>
      <nav className="right">
        <div className="left">
          <Link to="/about">About</Link>
          <Link to="/contact">Contact</Link>
        </div>
        <div className="right">
          <Link to="/classes">Classes</Link>
          <Link to="/signup">Signup</Link>
        </div>
      </nav>
    </div>
  </header>
)

Header.propTypes = {
  toggleMenu: PropTypes.func,
}

export default Header
