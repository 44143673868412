import * as React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./footer"

import "../css/reset.css"
import "../css/main.css"
import "../css/header.css"
import "../css/article.css"
import "../css/section-hero.css"
import "../css/section-benefits.css"
import "../css/section-classes.css"
import "../css/section-map.css"
import "../css/tables.css"
import "../css/forms.css"
import "../css/footer.css"

const PARALLAX_HEIGHT = 480

const Layout = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)

  React.useEffect(() => {
    const doParallaxes = () => {
      const parallaxes = document.querySelectorAll('section[role="parallax"]')
      const { scrollY } = window

      if (!parallaxes.length) return

      parallaxes.forEach(section => {
        const passed = scrollY - section.offsetTop
        let bgOffsetPerc

        if (passed < 0) bgOffsetPerc = 100
        else if (passed > PARALLAX_HEIGHT) bgOffsetPerc = 0
        else bgOffsetPerc = 100 - (passed / PARALLAX_HEIGHT) * 100

        section.style.backgroundPosition = `50% ${bgOffsetPerc}%`
      })
    }

    window.addEventListener("resize", doParallaxes)
    window.addEventListener("scroll", doParallaxes)
    doParallaxes()

    return () => {
      window.removeEventListener("resize", doParallaxes)
      window.removeEventListener("scroll", doParallaxes)
    }
  }, [])

  return (
    <div className={isMenuOpen ? "menuOpen" : undefined}>
      <Header toggleMenu={() => setIsMenuOpen(!isMenuOpen)} />
      <article>{children}</article>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
