import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Footer = () => (
  <footer>
    <div className="wrapper">
      <Link to="/" className="branding">
        <StaticImage
          src="../images/logo-color.png"
          alt="The Joy of Tai Chi"
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
        />
      </Link>
      <p className="legal">&copy; Copyright 2021 JoyOfTaiChi.com</p>
    </div>
  </footer>
)

export default Footer
